.container-layout {
  .col-right.collapsed,
  .col-left.collapsed {
    ultra-nav-item {
      div.item {
        width: 200px;
      }
    }
  }

  .col-right:not(.collapsed),
  .col-left:not(.collapsed) {
    ultra-nav-item {
      .item {
        width: 40px;

        .name {
          opacity: 0;
        }
      }
    }
  }
}
