@import '../../../assets/scss/tools';

$tooltipBackground: $grey-dark-2-standard-bg;

.item {
  position: relative;
  width: 100%;
  opacity: 1;

  &__icon-container {
    position: relative;
    height: 56px;

    .icon {
      a {
        width: 32px;
        height: 32px;
        background-size: 32px 32px;
        background-position: center center;
        background-repeat: no-repeat;
        outline: none;
        border: none;
      }
    }
  }

  &__tooltip {
    max-width: 130px;

    .tooltip-inner {
      padding: 5px;
      background-color: $tooltipBackground;
      font-size: 9px;
    }

    .tooltip-arrow {
      display: none;
    }
  }

  &.item-app {
    &.active {
      &:after {
        content: ' ';
        position: absolute;
        background: $ultra-purple;
        width: 5px;
        height: 36px;
        top: 10px;
        border-radius: 5px 0 0 5px;
        right: -15px;
        opacity: 1;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  & a {
    cursor: not-allowed !important;
  }
}
